import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
    },
    {
        path: '/driver',
        name: 'driver',
        component: () => import('../views/Driver.vue')
    },
    {
        path: '/stop',
        name: 'stop',
        component: () => import('../views/Stop.vue')
    },
    {
        path: '/address',
        name: 'address',
        component: () => import('../views/Address.vue')
    },
    {
        path: '/number',
        name: 'number',
        component: () => import('../views/Number.vue')
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/Order.vue')
    },
    {
        path: '/raport',
        name: 'raport',
        component: () => import('../views/Raport.vue')
    },
    {
        path: '/export',
        name: 'export',
        component: () => import('../views/Export.vue')
    },
    {
        path: '/operators',
        name: 'operators',
        component: () => import('../views/Operators.vue')
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import('../views/Statistics.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
