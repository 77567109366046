import Vue from 'vue';
import Vuetify, { VBtn, VIcon } from 'vuetify/lib';
import pl from 'vuetify/es5/locale/pl'

Vue.use(Vuetify, {
    components: {
        VBtn,
        VIcon
    }
});

export default new Vuetify({
    lang: {
        locales: { pl },
        current: 'pl',
    },
    icons: {
        iconfont: 'mdi',
    },
});
