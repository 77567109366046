<template>
    <div
        class="list-order"
        ref="draggableListOrder"
        @click="listOrdersActive = (!listOrdersClose)"
        :class="{'list-order--draggable': draggable, 'list-order--active': listOrdersActive, 'list-order--close': listOrdersClose}">
        <div class="list-order__header d-flex">
            <h4
                class="list-order__h4"
                @mousedown="dragMouseDown">
                <v-icon color="white" class="mr-1">mdi-calendar-text-outline</v-icon>
                Lista zleceń
            </h4>
            <v-icon @click="hideListOrders" color="white" class="ml-3" tabindex="-1" v-if="!listOrdersClose">mdi-close</v-icon>
            <v-icon @click="hideListOrders" color="white" class="ml-3" tabindex="-1" v-if="listOrdersClose">mdi-arrow-top-left</v-icon>
        </div>
        <ul class="list-orders" v-if="callListLocal && callListLocal.length">
            <template
                v-for="(item, index) in callListLocal">
                <li :key="item.timestamp"
                    v-if="item.caller_data"
                    class="item d-flex align-center"
                    @click="openModalOrder(index)">
                    <span class="item__icon d-flex">
                        <v-icon color="white" v-if="item.data_type === 'scheduled'">mdi-clock-alert-outline</v-icon>
                        <v-icon color="white" v-else-if="item.data_type === 'edit'">mdi-pencil</v-icon>
                        <v-icon color="white" v-else>mdi-phone</v-icon>
                        <v-icon color="white" v-if="item.openModal">mdi-eye-outline</v-icon>
                        <v-icon color="white" v-else>mdi-eye-off-outline</v-icon>
                    </span>
                    <span class="item__time">
                        {{ item.time }}
                    </span>
                    <span class="item__phone">
                        {{ formatNumberPhone(item.number) }}
                    </span>
                    <span class="item__address">
                        {{ item.caller_data.street }} {{ item.caller_data.no_home }} {{ item.caller_data.no_flat }}
                    </span>
                    <span class="item__city">
                        {{ item.caller_data.city }}
                    </span>
                </li>
            </template>
        </ul>
        <div v-else class="list-orders__info text-center">
            brak
        </div>
        <div class="d-none list-orders__info text-center">
            brak
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalOrdersList",
    props: {
        callList: {
            type: Array,
            required: true,
        },
        activeListOrdersModal: {
            type: Boolean,
            required: true
        },
        formatNumberPhone: {
            type: Function
        }
    },
    data: () => ({
        callListLocal: [],
        positions: {
            clientX: undefined,
            clientY: undefined,
            movementX: 0,
            movementY: 0,
        },
        max: {
            top: null,
            left: null
        },
        listOrdersActive: false,
        listOrdersClose: false,
        draggable: false
    }),
    watch: {
        callList(n) {
            this.callListLocal = n;
        },
        activeListOrdersModal (n) {
            if (n) {
                this.listOrdersActive = true;
                this.listOrdersClose = false;
            }
        }
    },
    created() {
        this.callListLocal = this.callList;
        this.max.top = window.innerHeight - 35;
        this.max.left = window.innerWidth - 730;
    },
    methods: {
        openModalOrder: function (index) {
            if(this.$route.name !== 'home') {
                this.$router.push('/');
            }
            this.callListLocal[index].openModal = true;
            this.callListLocal[index].miniModal = false;
            this.$emit('updateItemCallList', {'index': index, 'item': this.callListLocal[index]});
        },
        hideListOrders: function () {
            if(this.listOrdersClose) {
                this.draggable = false;
                this.listOrdersActive = false;
                this.$refs.draggableListOrder.style.top = '';
                this.$refs.draggableListOrder.style.left = '';
            }
            this.listOrdersClose = !this.listOrdersClose;
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            if(!this.listOrdersClose) {
                this.positions.clientX = event.clientX
                this.positions.clientY = event.clientY
                document.onmousemove = this.elementDrag
                document.onmouseup = this.closeDragElement
            } else {
                this.hideListOrders()
            }
        },
        elementDrag: function (event) {
            event.preventDefault();
            this.draggable = true;
            this.listOrdersActive = true;
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            const draggableTop = this.$refs.draggableListOrder.offsetTop - this.positions.movementY;
            const draggableLeft = this.$refs.draggableListOrder.offsetLeft - this.positions.movementX;
            this.$refs.draggableListOrder.style.top = (draggableTop >= 85 ? (draggableTop <= this.max.top ? draggableTop : this.max.top) : 85) + 'px'
            this.$refs.draggableListOrder.style.left = (draggableLeft >= 0 ? (draggableLeft <= this.max.left ? draggableLeft : this.max.left) : 0) + 'px'
        },
        listOrdersDeactivate () {
            this.listOrdersActive = false;
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        }
    },
    mounted() {
        if(!this.listOrdersActive) {
            window.addEventListener('mouseup', this.listOrdersDeactivate);
        }
    },
    beforeDestroy() {
        window.removeEventListener('mouseup', this.listOrdersDeactivate);
    }
}
</script>

<style lang="scss">
    .list-order {
        position: fixed;
        right: 5px;
        bottom: 5px;
        width: 730px;
        border: 1px solid #45a9f9;
        background-color: #0e4072;
        user-select: none;
        transition: {
            duration: .2s;
            property: opacity;
        };
        opacity: .5;

        &--draggable {
            right: auto;
            bottom: auto;
        }

        &--close {
            top: auto !important;
            left: auto !important;
            right: 5px;
            bottom: 5px;
            width: auto;

            .list-order__h4 {
                cursor: pointer;
            }

            .list-orders, .list-orders__info {
                display: none;
            }
        }

        &:hover {
            opacity: .75;
        }

        &.list-order--active {
            opacity: 1;
            z-index: 200;
        }

        &__header {
            padding: 5px 15px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #45a9f9;

            .v-icon {
                &:not(.mdi-calendar-text-outline) {
                    cursor: pointer;
                }

                &:focus::after {
                    display: none;
                }
            }
        }

        &__h4 {
            display: block;
            width: 100%;
            color: white;
            font-size: .875rem;
            text-transform: uppercase;
            cursor: move;
        }

        .list-orders {
            overflow-y: auto;
            position: relative;
            max-height: 120px;
            padding: 0;
            list-style: none;
            background-color: #125394;
            background-image: linear-gradient(140deg, rgba(25, 81, 160, 0.45) 0%, rgba(0, 116, 211, 0.30) 100%) !important;

            .item {
                justify-content: space-around;
                padding: 0 15px;
                border-bottom: 1px solid rgba(255,255,255,0.35);
                cursor: pointer;

                &:nth-of-type(even) {
                    background-color: rgba(0,0,0,0.15);
                }

                &:hover {
                    background-color: rgba(255,255,255,0.15);
                }

                span {
                    display: block;
                    color: white;
                    overflow: hidden;
                    position: relative;
                }

                &__icon {
                    min-width: 75px;
                    flex: 0 0 75px;
                    margin-right: 10px;
                    text-align: center;

                    .v-icon:last-child {
                        margin-left: 5px;
                    }
                }

                &__time {
                    min-width: 50px;
                    flex: 0 0 50px;
                    margin-right: 10px;
                    text-align: center;
                }

                &__phone {
                    margin-right: 10px;
                    min-width: 140px;
                    flex: 0 0 140px;
                }

                &__address {
                    margin-right: 10px;
                    width: 50%;
                    min-width: 250px;
                }

                &__city {
                    min-width: 140px;
                    flex: 0 0 140px;
                    font-size: 12px;
                }
            }
        }

        &:not(.list-order--close) .list-orders:empty ~ .list-orders__info {
            display: block!important;
        }

        .list-orders__info {
            padding: 10px;
            color: #fff;
            background-color: #125394;
            background-image: linear-gradient(140deg, rgba(25, 81, 160, 0.45) 0%, rgba(0, 116, 211, 0.30) 100%) !important;
        }
    }
</style>
