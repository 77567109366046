<template>
    <div>
        <v-card tile>
            <v-list flat>
                <v-list-item-group>
                    <template v-for="(item, index) in listDriver">
                        <v-list-item tabindex="-1" :key="item.call_number" @click="changeDriverStatus(item)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-chip
                                        class="mr-1 px-2"
                                        small
                                        label
                                        v-if="!item.working"
                                    >
                                        {{ item.call_number }}
                                    </v-chip>
                                    <v-chip
                                        class="mr-1 px-2"
                                        small
                                        label
                                        v-else
                                        color="primary"
                                    >
                                        {{ item.call_number }}
                                    </v-chip>
                                    {{ item.first_name }} {{ item.last_name | firstLetter }}
                                    <v-tooltip right v-if="daysLeftColor(item.licence_expiration)">
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" small class="mr-2" color="red darken-1">mdi-alert-octagon</v-icon>
                                        </template>
                                        <span>Przypomnieć kierowcy o kończącej się licencji - {{ item.licence_expiration }}</span>
                                    </v-tooltip>
                                    <v-tooltip right v-if="item.has_terminal">
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" small class="mr-2" color="yellow darken-1">mdi-credit-card</v-icon>
                                        </template>
                                        <span>Terminal płatniczy</span>
                                    </v-tooltip>
                                    <v-tooltip right v-if="item.has_sw">
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" small class="mr-2" color="orange darken-1">mdi-car-estate</v-icon>
                                        </template>
                                        <span>Kombi</span>
                                    </v-tooltip>
                                    <v-tooltip right v-if="item.has_van && (!item.max_seats || item.max_seats === '0')">
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" small class="mr-2" color="purple darken-1">mdi-van-passenger</v-icon>
                                        </template>
                                        <span>Van</span>
                                    </v-tooltip>
                                    <v-tooltip right v-if="item.max_seats && item.max_seats !== '0' && item.has_van">
                                        <template v-slot:activator="{ on }">
                                            <v-badge overlap>
                                                <template v-slot:badge>
                                                    <span>{{ item.max_seats }}</span>
                                                </template>
                                                <v-icon v-on="on" small class="mr-2" color="purple darken-1">mdi-van-passenger</v-icon>
                                            </v-badge>
                                        </template>
                                        <span>Van na {{ item.max_seats }} osób (pasażerów)</span>
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-tooltip right v-if="item.working && item.select_first_stop">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            v-on:click="resetDriverSelectFirst(item.uid)"
                                            v-on:click.stop
                                            :color="(item.select_first_reason === typeReason[0] ? 'green' : item.select_first_reason === typeReason[1] ? 'orange' : 'red')"
                                            tabindex="-1"
                                        >
                                            mdi-flag-variant
                                        </v-icon>
                                    </template>
                                    <span>Na postoju: {{ getStopName(item.select_first_stop) }} - ({{ item.select_first_reason }})</span>
                                </v-tooltip>
                                <v-tooltip right v-if="item.working && !item.select_first_stop" tabindx="-1">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            v-on:click.stop="openDialog(item)"
                                            tabindex="-1"
                                        >
                                            mdi-flag-variant
                                        </v-icon>
                                    </template>
                                    <span>Ustaw pudło</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider
                            v-if="index + 1 < listDriver.length"
                            :key="index"
                        />
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-dialog
            v-model="dialog"
            max-width="330"
        >
            <v-card>
                <v-form
                    autocomplete="off"
                    ref="form"
                    @submit.prevent="setDriverSelectFirst(sheetData.uid, stopIdSelect, reasonSelect)"
                    v-model="validDriverSelectFirst"
                    lazy-validation
                >
                    <v-card-title>
                        <span class="headline">Na którym postoju<br />będzie kierowca?</span>
                    </v-card-title>
                    <v-card-text class="pt-3 pb-0">
                        <v-autocomplete
                            label="Postój"
                            :items="stopsMap"
                            v-model="stopIdSelect"
                            :rules="[v => !!v || 'Musisz wybrać postój na którym będzie kierowca po pudełku.']"
                            dense
                            auto-select-first
                            item-text="name"
                            item-value="id"
                            required
                            outlined
                        />
                    </v-card-text>
                    <v-card-text class="pt-3 pb-0">
                        <v-autocomplete
                            label="Powód"
                            :items="typeReason"
                            v-model="reasonSelect"
                            :rules="[v => !!v || 'Musisz wybrać powód \'pudła\'.']"
                            dense
                            auto-select-first
                            item-text="value"
                            item-value="value"
                            required
                            outlined
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>

                        <v-btn
                            tile
                            small
                            outlined
                            color="error"
                            @click="dialog = false"
                        >
                            Anuluj
                        </v-btn>

                        <v-btn
                            tile
                            small
                            color="primary"
                            type="submit"
                            :disabled="!validDriverSelectFirst"
                        >
                            Potwierdź
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";

    export default {
        name: 'ActiveDrivers',
        props: {
            daysLeftColor: {
                type: Function
            },
            resetDriverSelectFirst: {
                type: Function
            },
        },
        data: () => ({
            selected: [2],
            dialog: false,
            typeReason: [
                'po zakupach',
                'po rabacie',
                'po niezrealizowanym kursie',
            ],
            sheetData: {
                call_number: null,
                select_first: null,
                select_first_stop: null,
                select_first_reason: null
            },
            stopIdSelect: null,
            reasonSelect: null,
            validDriverSelectFirst: true,
            on: null,
        }),
        filters: {
            firstLetter: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + '.';
            }
        },
        methods: {
            openDialog(data_driver) {
                if(this.$refs.form)
                    this.$refs.form.reset();
                this.sheetData = data_driver;
                this.dialog = true;
            },
            getStopName: function(stopID) {
                if (this.stopsMap.length) {
                    return this.stopsMap.filter(( item ) => item.id === stopID)[0].name;
                }else {
                    return null;
                }
            },
            changeDriverStatus(data_driver) {
                this.sheetData = data_driver;
                if(data_driver.working) {
                    if(data_driver.select_first_stop)
                        this.resetDriverSelectFirst(data_driver.uid);
                    Vue.prototype.$socket.sendObj({"action": "set_driver_off", "driver_id": data_driver.uid});
                } else {
                    Vue.prototype.$socket.sendObj({"action": "set_driver_working", "driver_id": data_driver.uid});
                }
            },
            setDriverSelectFirst:  function (driver_uid, stop_id, reason) {
                if (this.$refs.form.validate()) {
                    Vue.prototype.$socket.sendObj({
                        "action": "set_driver_select_first",
                        "driver_id": driver_uid,
                        "stop": stop_id,
                        "reason": reason
                    });
                    this.dialog = false;
                }
            },
        },
        computed: {
            listDriver() {return this.$store.state.socket.listDriver},
            stopsMap () {return this.$store.state.socket.stopsMap},
        },

    };
</script>

<style lang="scss">
    .v-navigation-drawer {
        overflow: visible;
        visibility: visible;
        &--open {
            z-index: 6!important;
        }
    }

    .v-main {
        margin-left: 36px;
    }

    .btn-toggle-list-driver {
        position: absolute;
        top: 84px;
        right: -36px;
        color: #111!important;
        background-color: yellow;
        border: 1px solid #5998c1;
        box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.15);

        @media (min-width: 1264px) {
            top: 0;
        }

        .v-badge__badge {
            padding: 4px 0;
        }
    }

    .v-list-item--link:not(:last-child) {
        border-bottom: 1px solid #656363;
        .theme--light & {
            border-color: #ddd;
        }
    }

    .v-chip.v-size--small {
        min-height: 18px;
        line-height: 18px;
    }

    .v-list-item__title {
        font-size: .875rem;
        text-align: left;
    }

    .v-badge__badge {
        width: 18px;
        height: 18px;
        font-size: 12px;
    }

    .driver-status {
        color: #b3b3b3;
        font-weight: 300;
    }
</style>
