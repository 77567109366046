import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    state: {
        socket: {
            isConnected: false,
            message: '',
            listDriver: {},
            listModems: {},
            callData: {},
            scheduledData: {},
            streetsMap: [],
            cityMap: [],
            namesMap: {},
            stopsMap: [],
            reconnectError: false,
            status: null
        },
    },
    mutations:{
        SOCKET_ONOPEN (state, event)  {
            Vue.prototype.$socket = event.currentTarget;
            state.socket.isConnected = true;
            Vue.prototype.$socket.sendObj({action:'get_modems'});
            Vue.prototype.$socket.sendObj({action:'get_stops'});
            Vue.prototype.$socket.sendObj({action:'get_drivers'});
            Vue.prototype.$socket.sendObj({action:'get_city_map'});
            Vue.prototype.$socket.sendObj({action:'get_streets_map'});
            Vue.prototype.$socket.sendObj({action:'get_names_map'});
            Vue.prototype.$socket.sendObj({action:'get_scheduled'});
            // Vue.prototype.$socket.sendObj({action:'self_test'});
            // Vue.prototype.$socket.sendObj({action:'connect_modem', modem_id:'modem1'});
            // Vue.prototype.$socket.sendObj({action:'connect_modem', modem_id:'Czytnik 2'});
            // Vue.prototype.$socket.sendObj({"action":"set_driver_working","driver_id": 12});
            state.socket.status = 'open';
        },
        SOCKET_ONCLOSE (state)  {
            state.socket.isConnected = false;
            state.socket.status = 'close';
        },
        SOCKET_ONERROR (state)  {
            // console.error(state, event)
            state.socket.status = 'error';
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE (state, message)  {
            // console.log('STORE -> SOCKET_ONMESSAGE -> message');
            // console.log(message);
            switch (message.data_type) {
                case 'drivers':
                    state.socket.listDriver = message.data;
                    break;
                case 'modems_list':
                    state.socket.listModems = message.data;
                    break;
                case 'call':
                    state.socket.callData = message;
                    break;
                case 'scheduled':
                    state.socket.scheduledData = message;
                    break;
                case 'streets_map':
                    state.socket.streetsMap = message.data;
                    state.socket.message = {
                        type: message.type,
                        data_type: message.data_type,
                    };
                    break;
                case 'city_map':
                    state.socket.cityMap = message.data;
                    state.socket.message = {
                        type: message.type,
                        data_type: message.data_type,
                    };
                    break;
                case 'names_map':
                    state.socket.namesMap = message.data;
                    state.socket.message = {
                        type: message.type,
                        data_type: message.data_type,
                    };
                    break;
                case 'stops_map':
                    state.socket.stopsMap = message.data;
                    break;
                default:
                    state.socket.message = message;
                    break;
            }
            switch (message.type) {
                case 'update_required_drivers':
                    Vue.prototype.$socket.sendObj({action:'get_drivers'});
                    break;
            }
            state.socket.status = 'message';
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state) {
            state.socket.status = 'reconnect';
            // console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.status = 'reconnect_error';
        },
    },
    actions: {
        sendMessage: function(context, message) {
            Vue.prototype.$socket.sendObj(message)
        },
        sendTest: function (message) {
            Vue.prototype.$socket.sendObj(message)
        }
    }
});
