import '@mdi/font/css/materialdesignicons.css'
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/800.css"
import "@fontsource/open-sans/latin.css"
import "@fontsource/open-sans/latin-ext.css"
import Vue from 'vue'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import config from './app.config'
import axios from 'axios';
import store from "./store"
import Vuetify from 'vuetify/lib'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueNativeSock from 'vue-native-websocket'
import Notifications from 'vue-notification'
import { VueMaskDirective } from 'v-mask'

Sentry.init({
    Vue,
    dsn: "https://7d15ea29f7a749a49ae93c86809407b7@o805869.ingest.sentry.io/5803291",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: config.environment,
});
Vue.use(Notifications)
Vue.use(Vuetify)
Vue.use(require('vue-moment'))
Vue.directive('mask', VueMaskDirective)

const base = axios.create({
    baseURL: config.api,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Content-Type': 'multipart/form-data'
    },
     withCredentials: false
});

Vue.prototype.$http = base;

Vue.use(VueNativeSock, config.monitor, {
    debug: true,
    store: store,
    format: 'json',
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    // reconnectionAttempts: 0, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 1000, // (Number) how long to initially wait before attempting a new (1000)
});
Vue.use(require('vue-shortkey'));

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');
