<template>
    <div
        :id="itemLocal.idElement"
        class="modal-order-form"
        ref="draggableListOrder"
        @click="activeModal"
        @focusin="activeModal"
        @keyup.tab="activeModal"
        @focusout="listOrdersActive = false"
        :class="{
            'modal-order-form--draggable': draggable,
            'modal-order-form--active': listOrdersActive,
            'modal-order-form--scheduled': itemLocal.data_type === 'scheduled' || itemLocal.data_type === 'scheduledEdit',
            'modal-order-form--edit': itemLocal.data_type === 'edit',
        }">
        <div class="modal-order-form__header d-flex">
            <h4
                class="modal-order-form__h4"
                @mousedown="dragMouseDown">
                <v-icon
                    color="white"
                    class="mr-1"
                    v-if="itemLocal.data_type === 'call' && itemLocal.reminder_time && itemLocal.reminder_time >= 0">
                    mdi-clock-outline
                </v-icon>
                <v-icon color="white" class="mr-1" v-else-if="itemLocal.data_type === 'edit'">mdi-pencil</v-icon>
                <v-icon color="white" class="mr-1" v-else-if="itemLocal.data_type !== 'scheduled' && itemLocal.data_type !== 'scheduledEdit'">mdi-phone</v-icon>
                <v-icon color="white" class="mr-1" v-else>mdi-clock-alert-outline</v-icon>
                <template v-if="itemLocal.data_type === 'edit'">Edycja #{{itemLocal.id}} - {{ formatNumberPhone(item.number) }}</template>
                <template v-else>{{ itemLocal.realizationTime }} - {{ formatNumberPhone(item.number) }}</template>
                <template v-if="itemLocal.data_type === 'call' && itemLocal.modem_id"> - {{ itemLocal.modem_id }}</template>
            </h4>
            <v-icon @click.stop="hideModalOrder" color="white" class="ml-3" tabindex="-1">mdi-window-minimize</v-icon>
            <v-icon @click.stop="cancel" color="white" class="ml-3 btn--close" v-shortkey="['esc']" @shortkey.native="cancelESC" tabindex="-1">mdi-close</v-icon>
        </div>
        <v-form
            autocomplete="off"
            aria-autocomplete="none"
            @submit.prevent="save"
            :ref="`form-${item.number}-${index}`"
            :id="`form-${item.number}-${index}`">
            <div class="modal-order-form__content pa-2" @click="item.miniModal = false">
                <v-row style="margin: 0 -4px 8px" v-if="!item.miniModal">
                    <v-col cols="6" class="py-0 px-1">
                        <v-text-field
                            class="phone-number"
                            v-shortkey="['alt', 't']"
                            @shortkey.native="focusField"
                            @change="getNumber"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keyup.enter="kickField"
                            ref="number"
                            v-model="itemLocal.number"
                            dense
                            outlined
                            hide-details
                            color="white"
                            aria-autocomplete="none"
                            :error-messages="errors.number"
                            @click="$event.target.select()"
                            @focusin="$event.target.select()"
                        >
                            <template slot="label">Numer <span class="label-shortcut">t</span>elefonu</template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0 px-1">
                        <v-combobox
                            v-shortkey="['alt', 's']"
                            @shortkey.native="focusField"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keyup.enter="kickField"
                            ref="short_name"
                            v-model="itemLocal.caller_data.short_name"
                            :items="namesList"
                            dense
                            outlined
                            hide-details
                            :filter="filterField"
                            auto-select-first
                            color="white"
                            aria-autocomplete="none"
                            @change="getAddressByName"
                        >
                            <template slot="label"><span class="label-shortcut">S</span>krócona nazwa</template>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row style="margin: 0 -4px 8px">
                    <v-col v-if="item.miniModal" cols="6" class="py-0 px-1">
                        <v-text-field
                            v-model="itemLocal.caller_data.short_name"
                            dense
                            outlined
                            hide-details
                            color="white"
                            aria-autocomplete="none"
                        >
                            <template slot="label"><span class="label-shortcut">S</span>krócona nazwa</template>
                        </v-text-field>
                    </v-col>
                    <v-col :cols="item.miniModal ? 6 : 12" class="py-0 px-1">
                        <v-combobox
                            v-shortkey="['alt', 'e']"
                            @shortkey.native="focusField"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keyup.enter="kickField"
                            ref="city"
                            v-model="itemLocal.caller_data.city"
                            :items="cityMap"
                            dense
                            outlined
                            hide-details
                            :filter="filterField"
                            auto-select-first
                            color="white"
                            aria-autocomplete="none"
                            :error-messages="errors.city"
                            @change="getStops(itemLocal.caller_data.city, itemLocal.caller_data.street, itemLocal.caller_data.no_home)"
                        >
                            <template slot="label">Mi<span class="label-shortcut">e</span>jscowość</template>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row style="margin: 0 -4px 8px">
                    <v-col cols="6" class="py-0 px-1">
                        <v-combobox
                            v-if="itemLocal.caller_data.city"
                            v-shortkey="['alt', 'a']"
                            @shortkey.native="focusField"
                            @keydown="selectStreetIntersection"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keyup.enter="kickField"
                            ref="street"
                            :search-input.sync="search"
                            v-model="itemLocal.caller_data.street"
                            :items="streetsMap"
                            dense
                            outlined
                            hide-details
                            :filter="filterField"
                            auto-select-first
                            color="white"
                            aria-autocomplete="none"
                            :error-messages="errors.city"
                            @change="getStops(itemLocal.caller_data.city, itemLocal.caller_data.street, itemLocal.caller_data.no_home)"
                        >
                            <template slot="label"><span class="label-shortcut">A</span>dres</template>
                        </v-combobox>
                        <v-text-field
                            v-else
                            v-shortkey="['alt', 'a']"
                            @shortkey="focusField"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keypress.enter="kickField"
                            ref="street"
                            v-model="itemLocal.caller_data.street"
                            dense
                            outlined
                            hide-details
                            color="white"
                            aria-autocomplete="none"
                            :error-messages="errors.street"
                            @change="getStops(itemLocal.caller_data.city, itemLocal.caller_data.street, itemLocal.caller_data.no_home)"
                        >
                            <template slot="label"><span class="label-shortcut">A</span>dres</template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0 px-1">
                        <v-text-field
                            v-shortkey="['alt', 'n']"
                            @shortkey.native="focusField"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keypress.enter="kickField"
                            ref="no_home"
                            v-model="itemLocal.caller_data.no_home"
                            dense
                            outlined
                            hide-details
                            color="white"
                            aria-autocomplete="none"
                            :error-messages="errors.no_home"
                            @click="$event.target.select()"
                            @focusin="$event.target.select()"
                            @change="getStops(itemLocal.caller_data.city, itemLocal.caller_data.street, itemLocal.caller_data.no_home)"
                        >
                            <template slot="label"><span class="label-shortcut">N</span>r. domu</template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0 px-1">
                        <v-text-field
                            ref="no_flat"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keypress.enter="kickField"
                            v-model="itemLocal.caller_data.no_flat"
                            label="Nr. lokalu"
                            dense
                            outlined
                            hide-details
                            color="white"
                            aria-autocomplete="none"
                            :error-messages="errors.no_flat"
                            @click="$event.target.select()"
                            @focusin="$event.target.select()"
                        />
                    </v-col>
                </v-row>
                <v-textarea
                    v-if="!item.miniModal"
                    v-shortkey="['alt', 'w']"
                    @shortkey.native="focusField"
                    @keydown.107.stop.prevent="moveMeToDriver"
                    @keypress.shift.enter.stop
                    @keypress.enter.exact="kickField"
                    class="mb-2"
                    ref="info1"
                    v-model="itemLocal.caller_data.info1"
                    dense
                    height="60"
                    outlined
                    hide-details
                    @keyup.enter.exact="save"
                    color="white"
                    @click="$event.target.select()"
                    @focusin="$event.target.select()"
                >
                    <template slot="label">U<span class="label-shortcut">w</span>agi</template>
                </v-textarea>
                <v-row style="margin: 0 -4px 0" v-if="!item.miniModal">
                    <v-col cols="4" class="py-0 px-1">
                        <v-text-field
                            v-shortkey="['alt', 'l']"
                            @shortkey.native="focusField"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keypress.enter="kickField"
                            ref="realizationDate"
                            v-model="itemLocal.realizationDate"
                            hint="YYYY-MM-DD"
                            v-mask="'XXXX-XX-XX'"
                            persistent-hint
                            dense
                            outlined
                            color="white"
                            :error-messages="errors.order_time"
                        >
                            <template slot="label">Rea<span class="label-shortcut">l</span>izacja</template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0 px-1">
                        <v-text-field
                            ref="realizationTime"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keypress.enter="kickField"
                            v-model="itemLocal.realizationTime"
                            hint="HH:MM"
                            v-mask="'XX:XX'"
                            label="Godzina"
                            persistent-hint
                            dense
                            outlined
                            color="white"
                            :error-messages="errors.order_time"
                        />
                    </v-col>

                    <v-col cols="4" class="py-0 px-1">
                        <v-text-field
                            v-shortkey="['alt', 'y']"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keypress.enter="kickField"
                            @keyup.enter="save"
                            @shortkey.native="focusField"
                            ref="reminder_time"
                            v-model="itemLocal.reminder_time"
                            suffix="min."
                            hint="Przypomnij przed"
                            persistent-hint
                            min="0"
                            dense
                            outlined
                            type="number"
                            color="white"
                            :error-messages="errors.reminder_time"
                        >
                            <template slot="label">Prz<span class="label-shortcut">y</span>pomnij przed</template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-tooltip :disabled="mishitDrivers.length === 0" open-on-click open-on-focus open-on-hover left :close-delay="500">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-shortkey="['alt', 'k']"
                            @keydown.107.stop.prevent="moveMeToDriver"
                            @keypress.enter="kickField"
                            @keyup.enter="save"
                            @shortkey.native="focusField"
                            class="mb-2"
                            ref="driver_number"
                            v-model="itemLocal.driver_number"
                            outlined
                            dense
                            color="white"
                            hide-details
                            @change="changeDriverID"
                            aria-autocomplete="none"
                            @click="$event.target.select()"
                            @focusin="$event.target.select()"
                            v-on="on"
                            :error-messages="errors.driver_number || errors.driver_id"
                        >
                            <template slot="label"><span class="label-shortcut">K</span>ierowca</template>
                        </v-text-field>
                    </template>
                    <span class="d-flex flex-column">
                        <span class="my-1" v-for="driver in mishitDrivers" :key="driver.id">
                            <v-chip
                                class="mr-1 px-2"
                                small
                                label
                            >
                                {{ driver.call_number }}
                            </v-chip>
                            {{ getStopNameForID(driver.select_first_stop) }} - <small>{{ driver.select_first_reason }}</small></span>
                    </span>
                </v-tooltip>
                <div class="modal-order-form__footer d-flex">
                    <div class="list-info">
                        <div class="list-info__headline">
                            <h4><v-icon color="white" small>mdi-map-marker</v-icon> Postoje</h4>
                        </div>
                        <div
                            class="list-info__content"
                            v-if="stops && stops.stop1 && stops.stop1 !== '0|RAW' && stops.stop1 !== '0'">
                            <div class="list-info__item" v-if="stops.stop1">
                                1. {{ stops.stop1 }}
                            </div>
                            <div class="list-info__item" v-if="stops.stop2">
                                2. {{ stops.stop2 }}
                            </div>
                            <div class="list-info__item" v-if="stops.stop3">
                                3. {{ stops.stop3 }}
                            </div>
                            <div class="list-info__item" v-if="stops.stop4">
                                4. {{ stops.stop4 }}
                            </div>
                            <div class="list-info__item" v-if="stops.stop5">
                                5. {{ stops.stop5 }}
                            </div>
                            <div class="list-info__item" v-if="stops.stop6">
                                6. {{ stops.stop6 }}
                            </div>
                        </div>
                        <div v-else class="list-info__empty text-center">
                            brak danych
                        </div>
                    </div>
                    <div class="list-info list-info--not-accepted-drivers">
                        <div class="list-info__headline">
                            <h4><v-icon color="white" small>mdi-alert</v-icon> Nie wysyłać</h4>
                        </div>
                        <div
                            class="list-info__content d-flex"
                            v-if="itemLocal.caller_data && Array.isArray(itemLocal.caller_data.not_accepted_drivers) && itemLocal.caller_data.not_accepted_drivers.length">
                            <template v-if="Array.isArray(itemLocal.caller_data.not_accepted_drivers)">
                                <div class="list-info__item" v-for="taxi in itemLocal.caller_data.not_accepted_drivers" :key="taxi">
                                    <v-icon color="white" small>mdi-taxi</v-icon> {{ taxi }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="list-info__item" v-for="taxi in JSON.parse(itemLocal.caller_data.not_accepted_drivers)" :key="taxi">
                                    <v-icon color="white" small>mdi-taxi</v-icon> {{ taxi }}
                                </div>
                            </template>
                        </div>
                        <div class="list-info__empty text-center" v-else>
                            brak danych
                        </div>
                    </div>
                    <div class="btn-wrap d-flex">
                        <v-btn @click="isSend = true;save()" :loading="loading" :disabled="loading">Zapisz</v-btn>
                        <v-btn color="error" class="btn--close" @click.stop="cancelBtn">Anuluj</v-btn>
                    </div>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "ModalOrderForm",
    props: {
        id: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        resetDriverSelectFirst: {
            type: Function
        },
        setCounterUpData: {
            type: Function,
            required: true
        },
        formatNumberPhone: {
            type: Function
        }
    },
    data: () => ({
        errors: [],
        itemLocal: {},
        positions: {
            clientX: undefined,
            clientY: undefined,
            movementX: 0,
            movementY: 0,
        },
        stops: {
          stop1: false,
          stop2: false,
          stop3: false,
          stop4: false,
          stop5: false,
          stop6: false,
        },
        max: {
            top: null,
            left: null
        },
        listOrdersActive: false,
        draggable: false,
        search: null,
        loading: false,
        isSend: false
    }),
    created() {
        this.itemLocal = JSON.parse(JSON.stringify(this.item));
        this.max.top = window.innerHeight - 35;
        this.max.left = window.innerWidth - 400;
        if(this.itemLocal.data_type === 'scheduled') {
            this.itemLocal.save_scheduled = true;
        }
        if(this.itemLocal.data_type === 'scheduledEdit') {
            this.itemLocal.scheduled_id = this.itemLocal.id;
        }
        if(this.itemLocal.stops && this.itemLocal.stops.stop1) {
            this.stops = this.itemLocal.stops;
        }
        if(!['scheduledEdit', 'scheduled', 'edit'].includes(this.itemLocal.data_type)) {
            this.item.miniModal = true;
        }
        this.itemLocal['number'] = this.formatNumberPhone(this.itemLocal.number);
    },
    watch: {
        item(n) {
          this.itemLocal = n;
        },
        search(n, o) {
            if (n === null) this.search = o;
        }
    },
    computed: {
        namesList() {return this.$store.state.socket.namesMap.all},
        cityMap() {return this.$store.state.socket.cityMap},
        streetsMap() {return this.$store.state.socket.streetsMap},
        listDriver() {return this.$store.state.socket.listDriver},
        stopsMap () {return this.$store.state.socket.stopsMap},
        mishitDrivers() {
            let mishitDriversObj = [];
            for (const [, value] of Object.entries(this.listDriver)) {
                if(value.select_first) {
                    mishitDriversObj.push(value)
                }
            }
            return mishitDriversObj
        }
    },
    methods: {
        getAddressByName() {
            const requestAPI = new FormData();

            requestAPI.append('asked_name', this.itemLocal.caller_data.short_name);

            this.$http.post('api/get_address_by_name', requestAPI)
                .then((response) => {
                    this.stops = response.data.stops;
                    this.itemLocal.city = response.data.city;
                    this.itemLocal.caller_data.city = response.data.city;
                    this.itemLocal.caller_data.street = response.data.street;
                    this.itemLocal.caller_data.no_home = response.data.no_home;
                    this.itemLocal.caller_data.no_flat = response.data.no_flat;
                    this.itemLocal.caller_data.info1 = response.data.info1;
                    this.$emit('updateItemCallList', {'index': this.index, 'item': this.itemLocal});
                })
                .catch(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Wystąpił błąd.',
                        text: 'Podczas pobierania adresu.'
                    });
                });
        },
        moveMeToDriver() {
            this.$refs.short_name.isMenuActive = false
            this.$refs.city.isMenuActive = false
            this.$refs.street.isMenuActive = false

            const elModal = document.getElementById(this.item.idElement);
            const fields = [...elModal.getElementsByTagName('input')];
            this.isSend = true;
            fields[12].focus();
        },
        kickField(e) {
            this.$refs.short_name.isMenuActive = false
            this.$refs.city.isMenuActive = false
            this.$refs.street.isMenuActive = false

            const elModal = document.getElementById(this.item.idElement);
            const fields = [...elModal.getElementsByTagName('input'), ...elModal.getElementsByTagName('textarea')];
            this.isSend = null;
            let index = [...fields].indexOf(e.target);
            let nextIndex = 0;

            switch (index) {
                case 0: // Numer telefonu
                    nextIndex = 1;
                    this.isSend = null;
                    break;
                case 1: // Skrócona nazwa
                    nextIndex = 3;
                    this.isSend = null;
                    break;
                case 3: // Miejscowość
                    nextIndex = 5;
                    this.isSend = null;
                    break;
                case 5: // Adres
                    nextIndex = 7;
                    this.isSend = null;
                    break;
                case 7: // Nr. domu
                    nextIndex = 8;
                    this.isSend = null;
                    break;
                case 8: // Nr. lokalu
                    nextIndex = 13;
                    this.isSend = null;
                    break;
                case 13: // Uwagi
                    nextIndex = 9;
                    this.isSend = false;
                    break;
                case 9: // Realizacja
                    nextIndex = 10;
                    this.isSend = null;
                    break;
                case 10: // Realizacja godzina
                    nextIndex = 11;
                    this.isSend = null;
                    break;
                case 11: // Przypomnienie
                    nextIndex = 12;
                    this.isSend = true;
                    break;
                case 12: // Kierowca
                    nextIndex = 12;
                    this.isSend = true;
                    break;
            }
            if (index < fields.length - 1 && nextIndex <= fields.length || nextIndex === 9) {
                fields[nextIndex].focus();
            }
        },
        activeModal(event) {
            if(event.target.classList && event.target.classList.value.includes('btn--close')) {
                return;
            }
            this.listOrdersActive = true;
            this.item.miniModal = false;

            if(!this.positions.modalTop && !this.positions.modalLeft && !['scheduledEdit', 'scheduled', 'edit'].includes(this.itemLocal.data_type)) {
                this.positions.modalTop = 85 + (36 * this.index) + 'px';
                this.positions.modalLeft = this.max.left - 423 + 'px';
                this.$refs.draggableListOrder.style.top = this.positions.modalTop;
                this.$refs.draggableListOrder.style.left = this.positions.modalLeft;
            }

            this.$emit('updateItemCallList', {'index': this.index, 'item': this.itemLocal});
        },
        getDriverData: function (id) {
            let driverData = {};
            if(id) {
                for (const [, value] of Object.entries(this.listDriver)) {
                    if(parseInt(value.call_number) === parseInt(id)) {
                        driverData = value;
                    }
                }
            }
            return driverData;
        },
        getStopNameForID: function (id) {
            const stopData = this.stopsMap.filter(( stop ) => stop.id === id);
            return stopData.length ? stopData[0].name : null;
        },
        filterField: function (item, queryText, itemText) {
            if(queryText && itemText) {
                return itemText.toLocaleLowerCase().replace(/(\55|\56|\s)/g, '')
                    .startsWith(queryText.toLocaleLowerCase().replace(/(\55|\56|\s)/g, ''))
            }
        },
        selectStreetIntersection: function (event) {
          if(event.keyCode === 191 || event.keyCode === 111) {
              event.preventDefault()
              const computedItems = this.$refs.street.computedItems;
              if(typeof computedItems !== 'undefined') {
                  const findStreet = computedItems.find(val => val.includes('/'));
                  this.search = findStreet && findStreet.substr(0, findStreet.indexOf('/') + 1);
              }
          }
        },
        focusField: function (event) {
            if(event.target.querySelector("div").closest(".modal-order-form.modal-order-form--active")) {
                if (typeof event.target.getElementsByTagName('input')[0] !== 'undefined') {
                    event.target.getElementsByTagName('input')[0].focus();
                } else if (typeof event.target.getElementsByTagName('textarea')[0] !== 'undefined') {
                    event.target.getElementsByTagName('textarea')[0].focus();
                }
            }
        },
        changeDriverID: function () {
            if (this.itemLocal.driver_number && this.itemLocal.driver_number.length === 2) {
                this.itemLocal.driver_number = '1' + this.itemLocal.driver_number;
            }
        },
        hideModalOrder: function () {
            this.listOrdersActive = false;
            this.itemLocal.openModal = false;
            this.$emit('updateItemCallList', {'index': this.index, 'item': this.itemLocal});
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag: function (event) {
            event.preventDefault()
            this.draggable = true;
            this.listOrdersActive = true;
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            const draggableTop = this.$refs.draggableListOrder.offsetTop - this.positions.movementY;
            const draggableLeft = this.$refs.draggableListOrder.offsetLeft - this.positions.movementX;
            this.positions.modalTop = (draggableTop >= 85 ? (draggableTop <= this.max.top ? draggableTop : this.max.top) : 85);
            this.positions.modalLeft = (draggableLeft >= 0 ? (draggableLeft <= this.max.left ? draggableLeft : this.max.left) : 0);
            this.$refs.draggableListOrder.style.top = this.positions.modalTop + 'px';
            this.$refs.draggableListOrder.style.left = this.positions.modalLeft + 'px';
        },
        listOrdersDeactivate () {
            this.listOrdersActive = false;
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
            this.itemLocal.positions = {};
            this.itemLocal.positions = this.positions;
            this.$emit('updateItemCallList', {'index': this.index, 'item': this.itemLocal});
        },
        getNumber () {
            const requestAPI = new FormData();

            requestAPI.append('asked_number', this.itemLocal.number.replace(/ /g,''));
            this.itemLocal.caller_data.number = this.itemLocal.number;

            this.$http.post('api/get_number', requestAPI) // Pobieranie listy postoi do zlecenia
                .then((response) => {
                    const data = response.data;
                    this.stops = data.stops;
                    this.itemLocal.city = data.city;
                    this.itemLocal.caller_data.city = data.city;
                    this.itemLocal.caller_data.street = data.street;
                    this.itemLocal.caller_data.short_name = data.short_name;
                    this.itemLocal.caller_data.no_home = data.no_home;
                    this.itemLocal.caller_data.no_flat = data.no_flat;
                    this.itemLocal.caller_data.info1 = data.info1;
                    this.itemLocal.caller_data.not_accepted_drivers = data.not_accepted_drivers;
                    this.$emit('updateItemCallList', {'index': this.index, 'item': this.itemLocal});
                })
                .catch(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Wystąpił błąd.',
                        text: 'Podczas pobierania listy postoi do zlecenia.'
                    });
                });
        },
        getStops (city, street, no_home) {
            const requestAPI = new FormData();

            requestAPI.append('city', city);
            requestAPI.append('street', street);
            requestAPI.append('number', no_home);

            this.$http.post('api/get_stops', requestAPI) // Pobieranie listy postoi do zlecenia
                .then((response) => {
                    this.stops = response.data;
                    this.itemLocal.stops = this.stops;
                    this.$emit('updateItemCallList', {'index': this.index, 'item': this.itemLocal});
                })
                .catch(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Wystąpił błąd.',
                        text: 'Podczas pobierania listy postoi do zlecenia.'
                    });
                });
        },
        cancelESC(event) {
            if(event.target.closest(".modal-order-form.modal-order-form--active")) {
                this.cancel();
            }
        },
        cancelBtn() {
            this.cancel();
            if(this.itemLocal.data_type === 'scheduledEdit') {
                Vue.prototype.$socket.sendObj({action:'set_cancel_scheduled', scheduled_id: this.itemLocal.id})
                this.$notify({
                    group: 'foo',
                    duration: 8000,
                    text: 'Anulowano czasówke'
                });
            }
        },
        cancel() {
            const sendInfo = {
                index: this.index,
                number: this.item.number,
                timestamp: this.item.timestamp
            };
            this.$emit('saveInfo', sendInfo);
        },
        save() {

            if(this.loading || !this.isSend) {
                return;
            }
            this.loading = true;
            Vue.prototype.$socket.sendObj({action:'get_drivers'});
            if(this.itemLocal.caller_data && Array.isArray(this.itemLocal.caller_data.not_accepted_drivers) && this.itemLocal.caller_data.not_accepted_drivers.includes(this.itemLocal.driver_number)) {
                this.errors.driver_number = 'Kierowca ' + this.itemLocal.driver_number + ' jest zastrzeżony przez tego klienta.';
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    text: this.errors.driver_number
                });
                this.itemLocal.driver_number = '';
                this.loading = false;
                return;
            }
            if(this.$refs['form-' + this.item.number + '-' + this.index].validate()) {
                this.itemLocal['number'] = this.itemLocal.number.replace(/ /g,'');
                const sendInfo = {
                    index: this.index,
                    number: this.item.number,
                    timestamp: this.item.timestamp
                };
                const driverData = this.getDriverData(this.itemLocal.driver_number);
                const form = {
                    type: (this.itemLocal.reminder_time <= 0 ? 'ON_CALL' : 'PLANNED'),
                    driver_id: (driverData ? driverData.id : this.itemLocal.driver_number),
                    driver_number: this.itemLocal.driver_number,
                    number: this.itemLocal.number,
                    short_name: this.itemLocal.caller_data.short_name,
                    city: this.itemLocal.caller_data.city,
                    street: this.itemLocal.caller_data.street,
                    stops: this.stops,
                    no_home: this.itemLocal.caller_data.no_home,
                    no_flat: this.itemLocal.caller_data.no_flat,
                    note: this.itemLocal.caller_data.info1,
                    operator_name: this.itemLocal.operator_name,
                    order_time: this.itemLocal.realizationDate + ' ' + this.itemLocal.realizationTime,
                    reminder_time: this.itemLocal.reminder_time,
                    realizationDate: this.itemLocal.realizationDate,
                    realizationTime: this.itemLocal.realizationTime,
                };
                let url = 'create_order_or_scheduled';
                if(this.itemLocal.data_type === 'scheduledEdit') {
                    url = 'p/schu/e/' + this.itemLocal.id;
                }
                if(this.itemLocal.data_type === 'edit') {
                    url = 'p/o/e/' + this.itemLocal.id;
                }

                this.$http.post(url, form) // Zapis
                    .then(() => {
                        this.errors = [];

                        if(this.itemLocal.driver_number && driverData.select_first_stop) {
                            this.resetDriverSelectFirst(driverData.call_number + ':' + driverData.id);
                            this.$notify({
                                group: 'foo',
                                type: 'success',
                                text: 'Kierowca ' + this.itemLocal.driver_number + ' był ' + driverData.select_first_reason
                            });
                        } else {
                            this.$notify({
                                group: 'foo',
                                type: 'success',
                                text: 'Zlecenie zapisano.'
                            });
                        }
                        if(this.itemLocal.driver_number && !driverData.working) {
                            Vue.prototype.$socket.sendObj({"action": "set_driver_working", "driver_id": driverData.uid});
                        }

                        this.$emit('saveInfo', sendInfo);
                        if(this.itemLocal.data_type !== 'edit' && this.itemLocal.data_type !== 'scheduledEdit') {
                            this.setCounterUpData('orders');
                        }
                        Vue.prototype.$socket.sendObj({action:'update_4_all_orders'});
                        Vue.prototype.$socket.sendObj({action:'get_scheduled'});
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.errors = error.response.data ? error.response.data.errors : null;
                        if(this.errors) {
                            let i = 0;
                            for (const error in this.errors) {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: this.errors[error],
                                });
                                if (i === 0) {
                                    const refField = (error === 'driver_id' ? 'driver_number' : error)
                                    this.$refs[refField].focus();
                                }
                                i++;
                            }
                        } else {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas zapisu zlecenia.'
                            });
                        }
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        }
    },
    mounted() {
        if(!this.listOrdersActive) {
            window.addEventListener('mouseup', this.listOrdersDeactivate);
        }

        if(this.itemLocal.positions) {
            this.positions = this.itemLocal.positions;
            this.draggable = true;
            this.$refs.draggableListOrder.style.top = this.positions.modalTop + 'px';
            this.$refs.draggableListOrder.style.left = this.positions.modalLeft + 'px';
        } else {
            this.draggable = true;
            this.$refs.draggableListOrder.style.top = 85 + (36 * this.index) + 'px';
            if(['scheduledEdit', 'scheduled', 'edit'].includes(this.item.data_type)){
                this.$refs.draggableListOrder.style.left = 348 + 'px';
            } else {
                this.$refs.draggableListOrder.style.left = this.max.left - 23 + 'px';
            }
        }
    },
    deactivated() {
        document.onmouseup = null
        document.onmousemove = null
        window.removeEventListener('mouseup', this.listOrdersDeactivate);
    }
}
</script>
