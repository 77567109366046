<template>
    <v-app id="app">
        <div class="taxi-background d-flex">
            <div class="taxi-background__crowns d-flex">
                <v-icon class="taxi-background__crown">mdi-crown</v-icon>
                <v-icon class="taxi-background__crown">mdi-crown</v-icon>
                <v-icon class="taxi-background__crown">mdi-crown</v-icon>
            </div>
            <span class="taxi-background__name"><span>TAXI</span> TRZY KORONY</span>
        </div>
        <v-navigation-drawer
            v-if="isLogged"
            v-model="drawer"
            v-click-outside="hideDrawer"
            app
            stateless
            clipped
            hide-overlay
            width="300"
        >
            <template
                v-if="isLogged"
                v-slot:prepend
            >
                <v-app-bar-nav-icon
                    v-if="isLogged"
                    class="btn-toggle-list-driver"
                    v-shortkey="['f10']"
                    @shortkey.native="drawer = !drawer; drawerScheduled = false"
                    @click.stop="drawer = !drawer; drawerScheduled = false"
                    tabindex="-1"
                >
                    <v-icon>mdi-taxi</v-icon>
                    <v-badge
                        bottom
                        color="success"
                        offset-x="0"
                        offset-y="-5"
                        :content="countActiveDrivers"
                    />
                </v-app-bar-nav-icon>
            </template>
            <ActiveDrivers
                :daysLeftColor="daysLeftColor"
                :resetDriverSelectFirst="resetDriverSelectFirst"
            />
        </v-navigation-drawer>
        <v-navigation-drawer
            v-if="isLogged && Object.keys(listScheduled).length"
            v-model="drawerScheduled"
            app
            stateless
            clipped
            hide-overlay
            width="300"
        >
            <template
                v-if="isLogged"
                v-slot:prepend
            >
                <v-app-bar-nav-icon class="btn-toggle-list-scheduled" v-if="isLogged" @click.stop="drawerScheduled = !drawerScheduled; drawer = false">
                    <v-icon>mdi-clock</v-icon>
                    <v-badge
                        bottom
                        color="error"
                        offset-x="0"
                        offset-y="-5"
                        :content="Object.keys(listScheduled).length"
                    />
                </v-app-bar-nav-icon>
            </template>
            <ListScheduled
                :listScheduled="listScheduled"
                :callList="callList"
                :userData="userData"
                :listDriver="listDriverAll"
                :formatNumberPhone="formatNumberPhone"
                @editCall="editCall"
            />
        </v-navigation-drawer>

        <v-app-bar
            app
            clipped-left
            color="#272727"
            dense
            height="36"
        >
            <template
                v-if="isLogged"
                v-slot:extension
            >
                <v-tabs
                    dark
                    grow
                    centered
                    color="yellow"
                    class="border-top-navbar"
                >
                        <v-tooltip
                            bottom
                            v-for="(item, index) in menuItems"
                            :key="index"
                        >
                            <template v-slot:activator="{ on }">
                                <v-tab
                                    tabindex="-1"
                                    v-on="on"
                                    v-shortkey="['f' + (index + 1)]"
                                    @shortkey.native="tabClick(item.route)"
                                    :to="item.route"
                                    active-class="yellow--text"
                                >
                                    <v-chip x-small label link class="px-1 mr-2" color="blue darken-2">
                                        F{{ index + 1 }}
                                    </v-chip>
                                    {{ item.text }}
                                </v-tab>
                            </template>
                            <span>Skrót klawiszowy: <strong>F{{ index + 1 }}</strong></span>
                        </v-tooltip>
                </v-tabs>
            </template>
            <v-toolbar-title class="headline-app-title mr-12 align-center">
                <router-link to="/" class="d-flex" tabindex="-1">
                    <v-icon class="yellow--text mr-2">mdi-crown</v-icon>
                    <span class="title font-weight-bold yellow--text">TAXI TRZY KORONY</span>
                </router-link>
                <template v-if="isLogged">
                    <span class="d-flex login-text font-weight-medium white--text">
                        <v-icon small color="white" class="mr-1">mdi-account</v-icon>
                        {{ userData.login }}
                    </span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <span class="d-flex login-text box-clock font-weight-bold ml-4" v-on="on">
                            <v-icon small class="mr-1">mdi-clock</v-icon>
                            {{ clock }}
                        </span>
                        </template>
                        <span>Czas pracy liczony od chwili zalogowania: {{ timeWorking }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <span class="d-flex login-text font-weight-medium white--text ml-4" v-on="on">
                            <v-icon small color="white" class="mr-1">mdi-phone</v-icon>
                            {{ userData.counter.phoneCalls }}
                        </span>
                        </template>
                        <span>Liczba połaczeń od chwili zalogowania</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <span class="d-flex login-text font-weight-medium white--text ml-4" v-on="on">
                            <v-icon small color="white" class="mr-1">mdi-check-circle</v-icon>
                            {{ userData.counter.orders }}
                        </span>
                        </template>
                        <span>Liczba zapisanych zleceń od chwili zalogowania</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <span class="d-flex login-text font-weight-medium white--text ml-4" v-on="on">
                            <v-icon small color="white" class="mr-1">mdi-cancel</v-icon>
                            {{ userData.counter.unrealizedOrders }}
                        </span>
                        </template>
                        <span>Liczba niezrealizowanych zleceń od chwili zalogowania. Tylko anulowane zlecenia w przegląd/zlecenia.</span>
                    </v-tooltip>
                </template>
                <span>
                    <v-btn
                        v-if="isLogged"
                        x-small
                        @click="createCall"
                        tabindex="-1"
                        class="ml-5 mb-1 font-weight-bold">
                        Utwórz zlecenie
                    </v-btn>
                    <v-btn
                        v-if="isLogged"
                        to="statistics"
                        x-small
                        tabindex="-1"
                        color="green darken-1"
                        class="ml-5 mb-1 font-weight-bold">
                        Statystyki
                    </v-btn>
                    <v-btn
                        v-if="isLogged && isDebug"
                        x-small
                        @click="createFakeCall"
                        tabindex="-1"
                        color="orange darken-1"
                        class="ml-5 mb-1 white--text">
                        Fake zlecenie
                    </v-btn>
                    <v-btn
                        v-if="isLogged && isDebug"
                        x-small
                        @click="(debug.fakeCall <= 0 ? manyFakeCall({openDialog: true}) : manyFakeCall({openDialog: false}))"
                        tabindex="-1"
                        color="red darken-1"
                        class="ml-5 mb-1 white--text">
                        Fake zlecenia
                    </v-btn>
                </span>
            </v-toolbar-title>

            <v-spacer/>
            <div class="d-flex">
                <div class="position-relative">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn tabindex="-1" icon @click="themeBrightness">
                                <v-icon color="yellow" v-if="themeDark" v-on="on">mdi-brightness-7</v-icon>
                                <v-icon color="yellow" v-if="!themeDark" v-on="on">mdi-brightness-2</v-icon>
                            </v-btn>
                        </template>
                        <span>Tryb jasny/ciemnny</span>
                    </v-tooltip>
                </div>
                <div class="position-relative" v-for="(modem, index) in listModems" :key="index">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn tabindex="-1" icon @click="receiveDataSwitch(modem.id)">
                                <v-icon color="green" v-if="modem.online" v-on="on">mdi-audio-video</v-icon>
                                <v-icon color="red" v-if="!modem.online" v-on="on">mdi-audio-video</v-icon>
                                <v-icon color="green" class="icon-receive-data" style="margin-left: -10px" x-small v-if="receiveData.includes(modem.id)" v-on="on">mdi-circle</v-icon>
                                <v-icon color="red" class="icon-receive-data" style="margin-left: -10px" x-small v-if="!receiveData.includes(modem.id)" v-on="on">mdi-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ modem.name }}</span>
                    </v-tooltip>
                </div>
            </div>
            <div class="position-relative">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon tabindex="-1" @click="showMenuServer">
                            <v-icon color="green" v-if="status === 'message' || status === 'open'" v-on="on">mdi-server</v-icon>
                            <v-icon color="orange" v-if="status === 'reconnect'" v-on="on">mdi-server</v-icon>
                            <v-icon color="red" v-if="status === 'reconnect_error' || status === 'error' || status === 'close'" v-on="on">mdi-server</v-icon>
                        </v-btn>
                    </template>
                    <span>Status odpowiedzi serwera: {{ status }}</span>
                </v-tooltip>
                <v-menu
                    v-model="menuServer.show"
                    :position-x="menuServer.x"
                    :position-y="menuServer.y"
                    absolute
                    offset-y
                >
                    <v-list dense>
                        <v-list-item-group
                            color="primary"
                        >
                            <v-list-item tabindex="-1" @click="resetMonitor">
                                <v-list-item-icon>
                                    <v-icon>mdi-server</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Zresetuj serwer</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item tabindex="-1" @click="resetModemMonitor">
                                <v-list-item-icon>
                                    <v-icon>mdi-audio-video</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Zresetuj modemy</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </div>
            <div class="position-relative" v-if="isLogged">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn tabindex="-1" icon @click="logout">
                            <v-icon class="white--text" v-on="on">mdi-logout</v-icon>
                        </v-btn>
                    </template>
                    <span>Wyloguj się</span>
                </v-tooltip>
            </div>
        </v-app-bar>

        <v-content v-if="!isLogged">
            <v-container fluid class="fill-height">
                <v-row dense class="justify-content-center">
                    <template v-if="operators.length">
                        <v-col
                            v-for="user in operators"
                            :key="user.name"
                            v-show="user.status === '1'"
                            cols="4"
                            class="px-5"
                        >
                            <v-card
                                color="orange darken-3"
                                dark
                                @click="setUser(user)"
                            >
                                <v-card-title class="headline justify-content-center py-5 my-5">
                                    {{ user.name }}
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </template>
                    <v-alert
                        v-else
                        border="left"
                        elevation="24"
                        type="error"
                    >Wystąpił problem z połączeniem do serwera. Spróbuj odświerzyć stronę! <strong>CTRL+F5</strong></v-alert>
                </v-row>
            </v-container>
            <keep-alive max="1">
                <notifications group="foo" :duration="5000" />
            </keep-alive>
        </v-content>

        <v-main v-if="isLogged">
            <v-container fluid class="fill-height align-content-start pb-6">
                <v-row>
                    <v-col>
                        <transition name="fade" mode="out-in">
                            <keep-alive max="1">
                                <router-view
                                    :listDriver="listDriverAll"
                                    :userData="userData"
                                    :message="message"
                                    :daysLeftColor="daysLeftColor"
                                    :setCounterUpData="setCounterUpData"
                                    :callList="callList"
                                    :formatNumberPhone="formatNumberPhone"
                                    @editCall="editCall"
                                />
                            </keep-alive>
                        </transition>
                    </v-col>
                </v-row>
            </v-container>
            <keep-alive max="1">
                <notifications group="foo" :duration="5000" />
            </keep-alive>
        </v-main>
        <template v-if="isLogged">
            <div
                v-for="(item, index) in callList"
                :key="item.timestamp"
            >
                <keep-alive :max="callList.length">
                    <modal-order-form
                        v-show="item.openModal && (item.data_type === 'edit' || $route.name === 'home')"
                        :id="item.idElement"
                        :index="index"
                        :item="item"
                        :resetDriverSelectFirst="resetDriverSelectFirst"
                        :formatNumberPhone="formatNumberPhone"
                        @saveInfo="saveInfo"
                        @updateItemCallList="updateItemCallList"
                        :setCounterUpData="setCounterUpData"/>
                </keep-alive>
            </div>
            <modal-orders-list
                :callList="callList"
                :activeListOrdersModal="activeListOrdersModal"
                :formatNumberPhone="formatNumberPhone"
                @updateItemCallList="updateItemCallListAndFocus"
            />
        </template>
        <v-dialog v-model="debug.fakeCallDialog" persistent max-width="450px">
            <v-card>
                <v-card-title>
                    <span class="headline">Wiele fałszywych połączeń</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="debug.fakeCall" type="number" label="Ilość połączeń*" required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="debug.fakeCallDialog = false">Anuluj</v-btn>
                    <v-btn color="blue darken-1" text @click="manyFakeCall({openModal: false, action: 'start'})">Wykonaj</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import Vue from "vue";
    import ActiveDrivers from "./components/ActiveDrivers";
    import ModalOrdersList from "./components/ModalOrdersList";
    import ModalOrderForm from "./components/ModalOrderForm";
    import ListScheduled from "./components/ListScheduled";
    import {formatNumber, isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js'

    export default {
        name: 'App',
        components: {
            ListScheduled,
            ModalOrderForm,
            ModalOrdersList,
            ActiveDrivers,
        },
        props: {
            source: String,
        },
        data: () => ({
            audio: null,
            response: '',
            drawer: false,
            drawerScheduled: false,
            menuItems: [
                { icon: 'mdi-taxi', text: 'Pulpit', route: '/' },
                { icon: 'mdi-cards', text: 'Przegląd/zlecenia', route: 'order' },
                { icon: 'mdi-phone', text: 'Klienci', route: 'number' },
                { icon: 'mdi-taxi', text: 'Kierowcy', route: 'driver' },
                { icon: 'mdi-near-me', text: 'Ulice', route: 'address' },
                { icon: 'mdi-flag', text: 'Postoje', route: 'stop' },
                { icon: 'mdi-file-document-box-check', text: 'Raporty', route: 'raport' },
                { icon: 'mdi-application-export', text: 'Eksporty', route: 'export' },
                { icon: 'mdi-application-export', text: 'Operatorzy', route: 'operators' },
            ],
            listDriver: [],
            listDriverAll: [],
            listScheduled: [],
            countActiveDrivers: 0,
            callList: localStorage.getItem('callList') ? JSON.parse(localStorage.getItem('callList')) : [],
            receiveData: localStorage.getItem('receiveData') ? JSON.parse(localStorage.getItem('receiveData')) : [],
            operators: [],
            isLogged: localStorage.getItem('isLogged'),
            userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
            themeDark: localStorage.getItem('themeDark') ? JSON.parse(localStorage.getItem('themeDark')) : false,
            isDebug: localStorage.getItem('debug') ? JSON.parse(localStorage.getItem('debug')) : false,
            debug: {
                fakeCallDialog: false,
                fakeCall: null,
            },
            activeListOrdersModal: false,
            timeWorking: null,
            clock: null,
            stopwatch: null,
            debugTimeout: undefined,
            modalTimeout: undefined,
            focusFieldEmptyTimeout: undefined,
            menuServer: {
                show: false,
                x: 0,
                y: 0
            }
        }),
        created () {
            this.getDrivers();
            this.getOperators();
            this.$vuetify.theme.dark = this.themeDark;
            this.getWorkTime();
            if (this.$route.query.debug) {
                this.isDebug = true;
                localStorage.setItem('debug', JSON.stringify(true));
                this.debugTimeout = setTimeout(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'TRYB DEBUGOWANIA WŁĄCZONY!',
                        text: 'Nie zapomnij wyłączyć go poprzez usunięcie w localStorage rekordu - debug'
                    });
                }, 1000)
            }
            this.audio = new Audio('./ding.mp3');
            window.addEventListener("keyup", this.keyupEvent);
        },
        mounted() {
            if (localStorage.getItem('callList')) {
                try {
                    this.callList = JSON.parse(localStorage.getItem('callList'));
                } catch(e) {
                    localStorage.removeItem('callList');
                }
            }
            if (localStorage.getItem('receiveData')) {
                try {
                    this.receiveData = JSON.parse(localStorage.getItem('receiveData'));
                } catch(e) {
                    localStorage.removeItem('receiveData');
                }
            }
            if (localStorage.getItem('isLogged')) {
                try {
                    this.isLogged = localStorage.getItem('isLogged');
                } catch(e) {
                    localStorage.removeItem('isLogged');
                }
            }
            if (localStorage.getItem('themeDark')) {
                try {
                    this.themeDark = JSON.parse(localStorage.getItem('themeDark'));
                } catch(e) {
                    localStorage.removeItem('themeDark');
                }
            }
            if (localStorage.getItem('userData')) {
                try {
                    this.userData = JSON.parse(localStorage.getItem('userData'));
                } catch(e) {
                    localStorage.removeItem('userData');
                }
            }
        },
        watch: {
            message: function (val) {
                switch (val.type) {
                    case 'error':
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: val.message
                        });
                        break;
                    case 'warning':
                        this.$notify({
                            group: 'foo',
                            type: 'warn',
                            text: val.message
                        });
                        break;
                    case 'success':
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: val.message
                        });
                        break;
                    default:
                        if(typeof val.message === 'string' && val.message) {
                            this.$notify({
                                group: 'foo',
                                text: val.message
                            });
                        }
                        break;
                }
            },
            callData: function (val) {
                if(this.isLogged && this.receiveData.includes(val.modem_id)) {
                    if(!this.callList.filter(( call ) => call.number === val.number).length) {
                        let actualCall = val;
                        const currentDate = new Date();

                        actualCall['idElement'] = this.getModalId(actualCall);
                        actualCall['caller_data']['city'] = val.caller_data.city ? val.caller_data.city : 'Pabianice';
                        actualCall['time'] = this.$moment(currentDate).format('HH:mm');
                        actualCall['timestamp'] = Date.now();
                        actualCall['operator_name'] = this.userData.login;
                        actualCall['reminder_time'] = null;
                        actualCall['realizationDate'] = this.$moment(currentDate).format('YYYY-MM-DD');
                        actualCall['realizationTime'] = this.$moment(currentDate).format('HH:mm');

                        this.callList.push(JSON.parse(JSON.stringify(actualCall)));

                        this.saveCallList();
                        this.setCounterUpData('phoneCalls');
                    }
                }
            },
            changeListDriver: function (data) {
                this.countActiveDriver(data);
            },
            scheduledData: function (data) {
                if(!data.data) {
                    this.playSound();
                    data['idElement'] = this.getModalId(data);
                    data['operator_name'] = this.userData.login;
                    data['realizationDate'] = this.$moment(data.caller_data.order_time * 1000).format('YYYY-MM-DD');
                    data['realizationTime'] = this.$moment(data.caller_data.order_time * 1000).format('HH:mm');
                    data['time'] = data['realizationTime'];
                    data['reminder_time'] = 0;
                    data['caller_data']['info1'] = data.note ? data.note : data.caller_data.info1;
                    this.callList.push(JSON.parse(JSON.stringify(data)));
                    this.saveCallList();
                } else {
                    this.listScheduled = data.data;
                }
            },
        },
        methods: {
            hideDrawer(e) {
                if((this.drawer || this.drawerScheduled) && e.target.className === "container fill-height align-content-start pb-6 container--fluid") {
                    this.drawer = false;
                    this.drawerScheduled = false;
                }
            },
            showMenuServer (e) {
                e.preventDefault()
                this.menuServer.show = false
                this.menuServer.x = e.clientX
                this.menuServer.y = e.clientY + 10
                this.$nextTick(() => {
                    this.menuServer.show = true
                })
            },
            keyupEvent (event) {
                if (event.key === 'Enter') {
                    this.enterAction();
                }
            },
            enterAction () {
                if(this.$route.name === 'home' && !document.getElementsByClassName('modal-order-form--active')[0]) {
                    let indexCall = 0;
                    for (const [index, value] of Object.entries(this.callList)) {
                        if(value.miniModal) {
                            indexCall = index;
                            break;
                        }
                    }
                    this.focusFieldEmpty({item: this.callList[indexCall], index: indexCall});
                }
            },
            formatNumberPhone (phoneNumber) {
                if(phoneNumber && isValidPhoneNumber(phoneNumber.toString(), 'PL')) {
                    let parsePhone = parsePhoneNumber(phoneNumber.toString(), 'PL');
                    return formatNumber(parsePhone.number, 'National')
                }
                return phoneNumber;
            },
            countActiveDriver (data) {
                this.countActiveDrivers = 0;
                for (const [, value] of Object.entries(data)) {
                    if(value.working || value.working_now) {
                        this.countActiveDrivers++;
                    }
                }
            },
            resetApp () {
                localStorage.clear();
                window.location.reload(true);
            },
            getModalId(data) {
                let idModal = 0;
                if(typeof data.idElement !== 'undefined') {
                    return data.idElement
                }
                if(typeof data.id !== 'undefined') {
                    idModal = data.id
                }
                if(typeof data.timestamp !== 'undefined') {
                    idModal = data.timestamp;
                }
                if (typeof data.number !== 'undefined' && data.number !== '555') {
                    idModal = data.number.replace(/ /g,'');
                }
                return 'modal-order-form-' + idModal;
            },
            tabClick (route) {
                let routeName = route === '/' ? 'home' : route;
                if(this.$route.name !== routeName)
                    this.$router.push(route);
            },
            daysLeftColor (date) {
                let daysLeft = this.$moment(date).diff(Date.now(), 'days');
                let timeThresholds = [3, 30];

                if (daysLeft <= timeThresholds[0]) {
                    return 'red--text';
                } else if (daysLeft > timeThresholds[0] && daysLeft <= timeThresholds[1]) {
                    return 'orange--text';
                } else {
                    return false;
                }
            },
            createCall() {
                const currentDate = new Date();

                let callObj = {
                    data_type: 'call',
                    modem_id: null,
                    modem_data: {
                        number: '555',
                    },
                    caller_data: {
                        number: '555',
                        city: 'Pabianice'
                    },
                    number: '555',
                    street: {},
                    time: this.$moment(currentDate).format('HH:mm'),
                    openModal: true,
                    miniModal: false,
                    operator_name: this.userData.login,
                    reminder_time: null,
                    realizationDate: this.$moment(currentDate).format('YYYY-MM-DD'),
                    realizationTime: this.$moment(currentDate).format('HH:mm'),
                    timestamp: Date.now()
                };
                let idElement = this.getModalId(callObj);
                callObj['idElement'] = idElement;
                this.callList.push(callObj);
                this.saveCallList();
            },
            editCall(callObj) {
                if(!this.callList.filter(( item ) => item.id === callObj.id).length) {
                    callObj['idElement'] = this.getModalId(callObj);
                    this.callList.push(callObj);
                    this.saveCallList();
                }
                this.focusFieldEmpty({item: callObj})
            },
            createFakeCall (){
                if(this.isLogged) {
                    Vue.prototype.$socket.sendObj({"action": "self_test"});
                }
            },
            manyFakeCall (args) {
                if (args.openDialog) {
                    this.debug.fakeCallDialog = true;
                } else {
                    this.debug.fakeCallDialog = false;
                    if (args.action === 'start' && this.debug.fakeCall > 0) {
                        Vue.prototype.$socket.sendObj({"action": "test_mode_on", "number_of_tests": this.debug.fakeCall});
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Włączone masowe połączenia testowe!',
                        });
                    } else {
                        this.debug.fakeCall = 0;
                        Vue.prototype.$socket.sendObj({"action": "test_mode_off"});
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wyłączone masowe połączenia testowe!',
                        });
                    }
                }
            },
            playSound () {
                this.audio.play();
            },
            logout() {
                this.resetApp();
                this.getOperators();
                clearTimeout(this.debugTimeout);
                clearTimeout(this.modalTimeout);
                clearTimeout(this.focusFieldEmptyTimeout);
                window.removeEventListener("keyup", this.keyupEvent);
            },
            setUser(user) {
                this.isLogged = 1;
                this.userData = {
                    uid: user.id,
                    login: user.name,
                    workStart: Date.now(),
                    orderSearchDateStart: Date.now(),
                    counter: {
                        orders: 0,
                        unrealizedOrders: 0,
                        phoneCalls: 0,
                    }
                };
                const parsed = JSON.stringify(this.userData);
                for (const [, modem] of Object.entries(this.listModems)) {
                    this.receiveDataSwitch(modem.id);
                }
                localStorage.setItem('isLogged', this.isLogged);
                localStorage.setItem('userData', parsed);
            },
            getOperators () {
                this.$http.get('a/u/l') // Pobieranie listy operatorów
                    .then((response) => {
                        this.operators = response.data;
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania listy operatorów.'
                        });
                    });
            },
            receiveDataSwitch(name) {
                if(!this.receiveData.includes(name)) {
                    this.receiveData.push(name);
                } else {
                    const index = this.receiveData.indexOf(name);
                    this.receiveData.splice(index, 1);
                }

                const parsed = JSON.stringify(this.receiveData);
                localStorage.setItem('receiveData', parsed);
            },
            getDrivers () {
                this.$http.get('p/d/l') // Pobieranie listy kierowców
                    .then((response) => {
                        this.listDriverAll = response.data;
                        // this.listDriver = this.listDriverAll.filter(( item ) => item.status === "1" && item.working_now === "1");
                        this.listDriver = this.listDriverAll.filter(( item ) => item.status === "1");
                        this.countActiveDriver(this.listDriverAll);
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania listy kierowców.'
                        });
                    });
            },
            // getStopList () {
            //     this.$http.get('p/s/l') // Pobieranie listy postoi
            //         .then((response) => {
            //             this.stopList = response.data;
            //         })
            //         .catch(() => {
            //             this.$notify({
            //                 group: 'foo',
            //                 type: 'error',
            //                 title: 'Wystąpił błąd.',
            //                 text: 'Podczas pobierania listy postoi.'
            //             });
            //         });
            // },
            removeCallList(x) {
                this.callList.splice(x, 1);
                this.saveCallList();
            },
            saveCallList () {
                const parsed = JSON.stringify(this.callList);
                localStorage.setItem('callList', parsed);
            },
            saveInfo (variable) {
                for(let i = 0; i < this.callList.length; i++) {
                    if (i === variable.index && this.callList[i].timestamp === variable.timestamp &&
                        ((this.callList[i].modem_data && this.callList[i].modem_data.number === variable.number) ||
                        (this.callList[i].number === variable.number))) {
                        this.callList[i].openModal = false;
                        this.removeCallList(i);
                    }
                }
                clearTimeout(this.debugTimeout);
                clearTimeout(this.modalTimeout);
            },
            updateItemCallList (args) {
                this.callList[args.index] = args.item;
                this.activeListOrdersModal = !args.item.openModal;
                this.saveCallList();
            },
            focusFieldEmpty(args) {
                if(args.item) {
                    this.modalTimeout = setTimeout(() => {
                        const elModal = document.getElementById(args.item.idElement ? args.item.idElement : this.getModalId(args.item));
                        if(elModal) {
                            elModal.click();
                            elModal.focus();
                            const fields = elModal.getElementsByTagName('input');
                            const indexField = args.item.number === '555' ? 0 : 5;
                            this.focusFieldEmptyTimeout = setTimeout(() => {
                                fields[indexField].focus();
                            }, 50)
                        }
                    }, 50)
                }
            },
            updateItemCallListAndFocus(args) {
                this.updateItemCallList(args);
                this.focusFieldEmpty(args);
            },
            resetDriverSelectFirst: function (driver_uid) {
                Vue.prototype.$socket.sendObj({"action": "reset_driver_select_first", "driver_id": driver_uid});
            },
            themeBrightness () {
                this.themeDark = !this.themeDark;
                this.$vuetify.theme.dark = this.themeDark;
                localStorage.setItem('themeDark', JSON.stringify(this.themeDark));
            },
            setCounterUpData: function (type) {
                this.userData.counter[type]++;
                const parsedUser = JSON.stringify(this.userData);
                localStorage.setItem('userData', parsedUser);
            },
            getWorkTime() {
                if(!this.stopwatch)
                    this.stopwatch = setInterval(() => {
                        if(typeof this.userData !== 'undefined' && this.userData && typeof this.userData.workStart !== 'undefined') {
                            this.clock = this.$moment().format("HH:mm:ss");
                            this.timeWorking = new Date(Date.now() - this.userData.workStart).toISOString().substr(11, 8);
                        } else {
                            this.timeWorking = null
                        }
                    }, 1000)
            },
            resetMonitor() {
                Vue.prototype.$socket.sendObj({action:'monitor_restart'})
            },
            resetModemMonitor() {
                Vue.prototype.$socket.sendObj({action:'modem_monitor_restart'})
            }
        },
        computed: {
            listModems () {
                return this.$store.state.socket.listModems;
            },
            status () {
                return this.$store.state.socket.status;
            },
            callData () {
                return this.$store.state.socket.callData;
            },
            scheduledData () {
                return this.$store.state.socket.scheduledData;
            },
            message () {
                return this.$store.state.socket.message;
            },
            changeListDriver () {
                return this.$store.state.socket.listDriver;
            },
        },
        filters: {
            url: function(string){
                if(string) {
                    return string.replace(':', '-');
                }
            }
        },
        beforeDestroy() {
            clearTimeout(this.debugTimeout);
            clearTimeout(this.modalTimeout);
            clearTimeout(this.focusFieldEmptyTimeout);
            window.removeEventListener("keyup", this.keyupEvent);
        }
    }
</script>
<style lang="scss">
    $font-family:'Open Sans';
    .v-application {
        [class*='text-'] {
            font-family: $font-family, sans-serif !important;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
        }
        font-family: $font-family, sans-serif !important;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }
    *:not(input, textarea, [tabindex="-1"]):focus {
        outline: 2px solid yellow!important;
    }
    .v-main__wrap {
        margin-bottom: 60px;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .headline-app-title {
        display: flex;
        a {
            color: #fff!important;
            text-decoration: none;
        }
    }
    .v-overlay--active {
        .v-overlay__scrim {
            background-color: rgba(2, 138, 255, .5)!important;
        }
        & + .v-application--wrap {
            filter: blur(5px);
        }
    }
    .v-tabs-bar {
        height: 30px;

        .v-tab {
            font: {
                size: 12px;
                weight: 700;
            }
            letter-spacing: 1px;
        }
        .v-tab--active {
            color: #fff;
        }
    }

    .v-list-item--link {

        &.v-list-item--disabled {
            color: #fff;
        }
    }

    .icon-receive-data {
        position: absolute;
        top: -4px;
        right: -2px;
    }
    .justify-content-center {
        justify-content: center;
    }
    .align-content-start {
        align-content: flex-start;
    }
    .login-text {
        margin-left: 3rem;
        font-size: 0.875rem;
    }
    .v-app-bar.v-toolbar.v-sheet {
        background-image: linear-gradient(140deg,rgba(25,81,160,0.95) 0%,rgba(0,116,211,0.86) 100%)!important;
    }
    .theme--light {
        &.v-application {
            background-color: #ECEFF1;
        }
        .v-card:not(.search-card) {
            .v-card__title + .v-card__text,
            .v-card__subtitle + .v-card__text{
                border: {
                    style: solid;
                    width: 1px 0 1px;
                    color: rgba(0,0,0,0.1);
                }
            }
        }
    }
    .theme--dark {
        &.v-application {
            background-color: #434647;
        }
        .v-card:not(.search-card) {
            .v-card__title + .v-card__text,
            .v-card__subtitle + .v-card__text {
                border: {
                    style: solid;
                    width: 1px 0 1px;
                    color: rgba(255, 255, 255, 0.1);
                }
            }
        }
    }

    .v-card__title {
        padding: .5rem 1rem;

        .headline {
            font-size: 1.15rem!important;
            line-height: 1.5rem;
            text-transform: uppercase;
        }
    }

    .v-list-item__mask {
        padding: 0 1px;
        color: #000!important;
        background-color: pink!important;
    }

    .taxi-background {
        position: absolute;
        top: 50%;
        left: 50%;
        flex-direction: column;
        align-items: center;
        transform: translate(-50%, -50%);
        pointer-events: none;
        &__crowns {
            align-items: flex-end;
        }
        &__crown.v-icon {
            margin: 0 -.5vw;
            font-size: 4vw;
            color: #d8dbdc;
            &:nth-child(2) {
                font-size: 5.5vw;
            }
            .theme--dark & {
                color: #525556;
            }
        }
        &__name {
            font: {
                size: 3vw;
                weight: 900;
            }
            color: #d8dbdc;
            line-height: 1.1em;
            text-align: center;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
            .theme--dark & {
                color: #525556;
            }
            span {
                font-weight: 500;
            }
        }
    }

    .v-data-footer {
        background-color: rgba(0,0,0,.1);
        &__select {
            .v-application--is-ltr & {
                margin-left: 14px;
            }
        }
    }

    .phone-number {
        input, .v-data-table__wrapper & {
            font: {
                size: 1.05em!important;
                weight: 700;
            }
        }
    }

    .box-clock {
        position: relative;
        color: #0b2449;
        font-weight: 700;
        .v-icon {
            color: #0b2449;
        }
        &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: -5px;
            right: -5px;
            bottom: -5px;
            border-radius: 0 0 6px 6px;
            background-color: yellow;
            z-index: -1;
        }
    }

    .v-tooltip__content {
        padding: 3px 10px;
        background-color: #111;
    }
    .v-btn:not(.v-btn--round).v-size--small {
        min-width: 25px;
        padding: 0 5px;
    }

    .v-btn, .v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot, .v-dialog, .v-data-table, .v-sheet.v-card, .v-tooltip__content {
        border-radius: 0;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: #dcdcdc;
    }

    ::-webkit-scrollbar-thumb {
        //background: #125394;
        background: #0b2449;
    }

    .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
        color: #fff;
        background-image: linear-gradient(140deg,rgba(25,81,160,0.95) 0%,rgba(0,116,211,0.86) 100%)!important;
        button {
            color: #fff;
        }
    }
    .w-100 {
        width: 100%;
    }
    .v-autocomplete__content.v-menu__content {
        z-index: 99999!important;
    }
    // MODEL ORDER FORM //
    .modal-order-form {
        position: fixed;
        top: 90px;
        right: 5px;
        width: 400px;
        border: 1px solid #45a9f9;
        background-color: #04111f;
        background-image: linear-gradient(140deg, rgba(25, 81, 160, 0.6) 0%, rgba(0, 116, 211, 0.35) 100%) !important;
        user-select: none;
        transition: {
            duration: .2s;
            property: opacity, filter;
        };
        filter: grayscale(1);
        opacity: .8;

        &--draggable {
            right: auto;
        }
        &--edit {
            border-color: #9945F9;
            background-color: #8900D3;
            background-image: linear-gradient(140deg, rgba(77, 25, 160, 0.6) 0%, rgba(8, 2, 196, 0.35) 100%) !important;
            .modal-order-form__header {
                border-bottom-color: #9945F9;
                background-color: #4D19A0;
            }
            .modal-order-form__content .v-text-field--outlined.v-input--is-focused .v-input__slot {
                background-color: #34106f !important;
            }
        }
        &--close {
            top: auto!important;
            left: auto!important;
            right: 5px;
            bottom: 5px;
            width: auto;

            .modal-order-form__h4 {
                cursor: auto;
            }

            .modal-order-form__content {
                display: none;
            }
        }

        &:hover {
            filter: grayscale(0);
            opacity: .9;
        }

        &.modal-order-form--active {
            opacity: 1;
            filter: grayscale(0);
            z-index: 200;
        }

        &.modal-order-form--scheduled {
            .modal-order-form__header {
                background-color: #fbc02d;

                h4, .v-icon {
                    color: #212121!important;
                }
            }
        }

        &:not(.modal-order-form--active) {
            .v-select, .v-autocomplete {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: transparent;
                    z-index: 2;
                }
            }
        }

        &__header {
            padding: 5px 15px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #45a9f9;
            background-color: #0e4072;

            .v-icon {
                &:not(.mdi-phone, .mdi-clock-outline, .mdi-clock-alert-outline) {
                    cursor: pointer;
                }

                &:focus::after {
                    display: none;
                }
            }
        }

        &__h4 {
            display: block;
            width: 100%;
            color: white;
            font-size: .875rem;
            text-transform: uppercase;
            cursor: move;
        }

        &__content {
            position: relative;
        }

        &__footer {
            justify-content: space-between;
            min-height: 80px;
        }

        .list-info {

            &__content {
                flex-flow: row wrap;
            }

            &__item {
                width: 50%;
                padding: 1px 3px;
                color: white;
                font-size: .875rem;
                white-space: nowrap;
            }

            &__headline {
                h4 {
                    color: #fff;
                    font-size: .75rem;
                    text-transform: uppercase;
                }
            }

            &__empty {
                color: #fff;
                font-size: 11px;
                padding: 1rem 0;
            }

            &--not-accepted-drivers {
                max-width: 110px;

                .list-info__content {
                    background-color: #b70909;
                    border-radius: 4px;
                    border: 1px solid red;
                }

                .list-info__item {
                    width: 100%;
                }
            }
        }

        .btn-wrap {
            flex-flow: column wrap;
        }
        // FORM //
        .v-btn {
            display: block;
            height: calc(100% - 46px)!important;
            color: #fff;
            background-color: #111!important;

            &.error {
                margin-top: auto;
                height: 36px !important;
                font-size: 12px;
            }
        }
        .v-messages__message {
            color: rgba(255, 255, 255, 0.7);
        }
        .v-input .v-icon, .v-label {
            color: white;
        }
        .v-label, .v-text-field__suffix, .v-input input {
            font-size: 14px;
        }
        .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
            color: rgba(255, 255, 255, 0.4);
        }

        .v-text-field--outlined.v-input--is-focused .v-input__slot {
            background-color: #0C66AC !important;
        }
        .v-input, .v-input input, .v-input textarea {
            color: white;
            caret-color: white;
        }

        .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) > .v-input__control > .v-input__slot:hover fieldset {
            color: white;
        }

        .v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
            border-color: white;
        }

        textarea {
            line-height: 1.2em;
        }
        .label-shortcut {
            //text-decoration: underline;
            padding: 0 1px;
            font-weight: 500;
            border-bottom: 1px solid #fff;
        }
        .v-input__icon {
            width: 18px;
            min-width: 18px;
            height: 26px;
            .v-icon.v-icon {
                font-size: 18px;
            }
        }
        .v-application--is-ltr .v-text-field--outlined fieldset {
            padding-left: 4px;
        }
        .v-text-field--outlined.v-input--dense .v-label--active {
            opacity: .4;
        }
        // / FORM //
    }
    // / MODEL ORDER FORM //
</style>

