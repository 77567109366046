<template>
    <div>
        <v-card tile>
            <v-list flat>
                <v-list-item-group>
                    <template v-for="(item, index) in listScheduled">
                        <v-list-item tabindex="-1" :key="index" @click="editItem(item)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div class="d-flex align-center">
                                        <div class="mr-2">
                                            <v-icon>mdi-clock-alert-outline</v-icon>
                                        </div>
                                        <div class="mr-3">
                                            <v-chip small label color="red darken-4" text-color="white" class="pa-1 mb-1">
                                                <strong>{{ $moment.unix(item.reminder_time).format('HH:mm') }}</strong>
                                            </v-chip><br />
                                            {{ $moment.unix(item.order_time).format('HH:mm') }}<br />
                                            <small class="text-no-wrap">{{ $moment.unix(item.order_time).format('YYYY-MM-DD') }}</small>
                                        </div>
                                        <div class="white-space-normal">
                                            <div class="mb-2">
                                                <v-chip color="primary" label small class="pa-1">
                                                    {{ formatNumberPhone(item.number) }}
                                                </v-chip>
                                            </div>
                                            {{ item.street }} {{ item.no_home }}
                                            {{ item.no_flat && 'm.'}}&nbsp;{{ item.no_flat }}
                                            <small>{{ item.city }}</small>
                                        </div>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "ListScheduled",
    props: {
        listScheduled: {
            type: Object,
            required: true
        },
        callList: {
            type: Array,
            required: true,
        },
        userData: {
            type: Object,
            required: true
        },
        listDriver: {
            type: Array,
            required: true
        },
        formatNumberPhone: {
            type: Function,
            required: true
        }
    },
    methods: {
        editItem (callScheduled) {
            if(this.$route.name !== 'home') {
                this.$router.push('/');
            }
            if(!this.callList.filter(( call ) => call.id === callScheduled.id).length) {
                this.$http.get('p/schu/g/' + callScheduled.id) // Pobieranie zamówienia do edycji
                    .then((response) => {
                        let item = response.data;
                        let callObj = {
                            id: item.id,
                            data_type: 'scheduledEdit',
                            modem_id: null,
                            operator_name: this.userData.login,
                            number: item.number,
                            caller_data: {
                                created: item.created,
                                event_time: item.event_time,
                                order_time: item.order_time,
                                number: item.number,
                                short_name: item.short_name,
                                name: item.name,
                                city: item.city,
                                street: item.street,
                                no_home: item.no_home,
                                no_flat: item.no_flat,
                                info1: item.note,
                                not_accepted_drivers: [],
                            },
                            driver_number: item.driver_id ? this.listDriver.filter(( driver ) => driver.id === item.driver_id)[0].driver_number : null,
                            realizationDate: this.$moment.unix(item.order_time).format('YYYY-MM-DD'),
                            realizationTime: this.$moment.unix(item.order_time).format('HH:mm'),
                            reminder_time: (item.order_time - item.reminder_time) / 60,
                            openModal: true,
                            miniModal: false,
                            stops: item.stops
                        };
                        this.$emit('editCall', callObj);
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania zamówienia.'
                        });
                    });
            } else {
                this.$emit('editCall', callScheduled);
            }
        },
    }
}
</script>

<style lang="scss">
.btn-toggle-list-scheduled {
    position: absolute;
    top: 130px;
    right: -36px;
    color: #111!important;
    background-color: yellow;
    border: 1px solid #5998c1;
    box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.15);

    @media (min-width: 1264px) {
        top: 46px;
    }
    .v-badge__badge {
        padding: 4px 0;
    }
}
.white-space-normal {
    white-space: normal;
}
</style>
